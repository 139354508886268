import React from 'react';

const IconStackExchange = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 75.298828 92.900391">
    <title>StackExchange</title>
    <path
      d="M 12,0 C 5.4,0 0,5.500781 0,12.300781 v 4 h 74.798828 v -4 C 74.798828,5.500781 69.599609,0 63.099609,0 Z M
      0,20.599609 V 36 H 74.798828 V 20.599609 Z M 0,40.5 V 55.900391 H 74.798828 V 40.5 Z m 0,19.800781 v 4 c 0,6.8
      5.4,12.298828 12,12.298828 H 44 V 92.90039 L 59.798828,76.599609 h 3.5 c 6.6,0 12,-5.498828 12,-12.298828 v -4 z"
    />
  </svg>
);

export default IconStackExchange;
