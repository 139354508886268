import React from 'react';

const IconGitLab = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 338.97595 313.43991">
    <title>GitLab</title>
    <path
      d="m 337.96982,175.57033 -17.78,-54.63 a 7.72,7.72 0 0 0 -0.43,-1.47 l -35.67,-109.8000035 a 14.18,14.18 0 0 0
      -13.54,-9.67000004639 13.94,13.94 0 0 0 -13.38,9.75000004639 l -34,104.6300035 h -107.31 L 81.809817,9.7503265 a
      13.94,13.94 0 0 0 -13.32,-9.75000004639 h -0.08 a 14.22,14.22 0 0 0 -13.5,9.76000004639 l -35.7,109.9300035 c
      0,0.1 -0.08,0.18 -0.11,0.28 l -18.10000009,55.61 a 20.29,20.29 0 0 0 7.36999999,22.71 l 156.3800031,113.63 a 8,8
      0 0 0 9.45,-0.05 l 156.41,-113.58 a 20.28,20.28 0 0 0 7.36,-22.72 m -233.75,-45.13 43.59,134.16
      -104.640003,-134.16 m 148.050003,134.19 41.8,-128.62 1.8,-5.57 h 61.1 l -94.67,121.28 m 69.44,-231.670004
      30.63,94.330004 h -61.31 m -22.03,16 -30.37,93.46 -18.12,55.66 -48.42,-149.12 M 68.339817,20.050326 l
      30.69,94.330004 h -61.27 m -19.98,70.97 a 4.31,4.31 0 0 1 -1.56,-4.83 l 13.44,-41.3 98.570003,126.37 m
      192.98,-80.24 -110.46,80.21 0.37,-0.48 98.2,-125.86 13.44,41.28 a 4.31,4.31 0 0 1 -1.55,4.84"
    />
  </svg>
);

export default IconGitLab;
