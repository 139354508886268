import React from 'react';

const IconTelegram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 135.67191 112.16991">
    <title>Telegram</title>
    <path
      d="m 8.3252477,48.851171 80.8100003,-33.295 c 7.977,-3.468 35.030002,-14.56599961 35.030002,-14.56599961 0,0
      12.486,-4.85499999 11.445,6.93600001 -0.347,4.8549996 -3.12,21.8499996 -5.896,40.2299996 l -8.67,54.449999 c 0,0
      -0.694,7.977 -6.6,9.364 -5.906,1.387 -15.607002,-4.855 -17.340002,-6.243 -1.387,-1.04 -26.012,-16.646999
      -35.03,-24.276999 -2.428,-2.08 -5.202,-6.243 0.347,-11.098 12.486,-11.445 27.4,-25.665 36.416,-34.682
      4.162002,-4.162 8.324002,-13.873 -9.017,-2.08 l -48.902,32.948 c 0,0 -5.55,3.468 -15.954,0.347 -10.404,-3.121
      -22.5430003,-7.283 -22.5430003,-7.283 0,0 -8.324,-5.202 5.896,-10.75 z"
    />
  </svg>
);

export default IconTelegram;
