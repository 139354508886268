import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import {graphql, useStaticQuery} from "gatsby";

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  const prefersReducedMotion = usePrefersReducedMotion();const {
    siteUrl,
  } = site.siteMetadata;

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="annals" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Annals</h2>

      <div><p>Sometimes, while brushing my teeth, or while feeding the Nifflers diamonds from my
        enormous Vault, I have a sudden
        stroke of inspiration, an idea of the utmost genius. This morning for example, I suddenly
        thought to my self:</p>
      <p><em>»Narwals are nothing but sea unicorns!«</em></p>
      <p>It is in such moments of ingenuity, that I might feel the need to inform the world of
          my findings and write them down in my annals.</p></div>

      <a className="email-link" href="/annals">
        Take Me There
      </a>
    </StyledContactSection>
  );
};

export default Contact;
